/// <reference path="types.d.ts" />

import { Dragon } from 'guardian';
import { getColor } from '../modules/ColorWheel';

export const INITAL_DRAGON: Dragon = {
  name: '',
  colors: {
    primary: getColor(-1),
    secondary: getColor(-1),
    tertiary: getColor(-1),
  },
  genes: {
    primary: '',
    secondary: '',
    tertiary: '',
  },
  sex: undefined,
  breed: undefined,
};

// read like: plentiful against rare -> plentiful percentage
export const RARITY_CHART = {
  plentiful: {
    plentiful: .5,
    common:  .7,
    uncommon: .85,
    limited: .97,
    rare: .99,
  },
  common: {
    plentiful: .3,
    common:  .5,
    uncommon: .75,
    limited: .9,
    rare: .99,
  },
  uncommon: {
    plentiful: .15,
    common:  .25,
    uncommon: .50,
    limited: .85,
    rare: .98,
  },
  limited: {
    plentiful: .03,
    common:  .1,
    uncommon: .15,
    limited: .5,
    rare: .97,
  },
  rare: {
    plentiful:  .01,
    common:  .01,
    uncommon: .02,
    limited: .03,
    rare: .5,
  },
};

export const MODERN_BREEDS = {
  'Bogsneak': 'uncommon',
  'Coatl': 'rare',
  'Fae': 'plentiful',
  'Guardian': 'plentiful',
  'Imperial': 'limited',
  'Mirror': 'plentiful',
  'Nocturne': 'limited',
  'Obelisk': 'uncommon',
  'Pearlcatcher': 'common',
  'Ridgeback': 'common',
  'Skydancer': 'uncommon',
  'Snapper': 'common',
  'Spiral': 'common',
  'Tundra': 'plentiful',
  'Wildclaw': 'rare',
}

export const MODERN_PRIMARY_GENES = {
  'Basic': 'plentiful',
  'Bar': 'uncommon', 
  'Cherub': 'uncommon', 
  'Clown': 'common', 
  'Crystal': 'rare', 
  'Fade': 'common', 
  'Falcon': 'common', 
  'Flaunt': 'uncommon', 
  'Giraffe': 'uncommon', 
  'Iridescent': 'rare', 
  'Jaguar': 'uncommon', 
  'Jupiter': 'uncommon', 
  'Laced': 'common', 
  'Leopard': 'common', 
  'Lionfish': 'uncommon', 
  'Metallic': 'rare', 
  'Mosaic': 'uncommon', 
  'Petals': 'rare', 
  'Piebald': 'common',
  'Pinstripe': 'limited', 
  'Poison': 'limited', 
  'Python': 'uncommon', 
  'Ribbon': 'uncommon', 
  'Ripple': 'uncommon', 
  'Savannah': 'common', 
  'Skink': 'limited', 
  'Slime': 'limited', 
  'Speckle': 'common', 
  'Starmap': 'rare', 
  'Stitched': 'limited', 
  'Swirl': 'common', 
  'Tapir': 'common', 
  'Tiger': 'common', 
  'Vipera': 'uncommon', 
  'Wasp': 'rare',
};

export const MODERN_SECONDARY_GENES = {
  'Basic': 'plentiful',
  'Alloy': 'rare',
  'Bee': 'rare',
  'Blend': 'common',
  'Breakup': 'uncommon',
  'Butterfly': 'rare',
  'Clouded': 'common',
  'Constellation': 'rare',
  'Current': 'uncommon',
  'Daub': 'common',
  'Edged': 'common',
  'Eel': 'uncommon',
  'Eye Spots': 'common',
  'Facet': 'rare',
  'Flair': 'uncommon',
  'Freckle': 'common',
  'Hex': 'uncommon',
  'Hypnotic': 'uncommon',
  'Marbled': 'common',
  'Morph': 'uncommon',
  'Noxtide': 'uncommon',
  'Paint': 'common',
  'Patchwork': 'Limited',
  'Peregrine': 'common',
  'Rosette': 'uncommon',
  'Safari': 'common',
  'Saturn': 'uncommon',
  'Seraph': 'uncommon',
  'Shimmer': 'rare',
  'Sludge': 'limited',
  'Spinner': 'limited',
  'Striation': 'common',
  'Stripes': 'common',
  'Toxin': 'limited',
  'Trail': 'limited',
};

export const MODERN_TERTIARY_GENES = {
  'Basic': 'plentiful',
  'Capsule': 'limited',
  'Circuit': 'rare',
  'Contour': 'common',
  'Crackle': 'uncommon',
  'Firefly': 'limited',
  'Filigree': 'rare',
  'Gembound': 'limited',
  'Ghost': 'uncommon',
  'Glimmer': 'rare',
  'Glowtail': 'rare',
  'Keel': 'limited',
  'Koi': 'rare',
  'Lace': 'uncommon',
  'Okapi': 'uncommon',
  'Opal': 'rare',
  'Peacock': 'common',
  'Ringlets': 'uncommon',
  'Runes': 'limited',
  'Scales': 'limited',
  'Smirch': 'limited',
  'Smoke': 'uncommon',
  'Spines': 'limited',
  'Stained': 'rare',
  'Thylacine': 'common',
  'Underbelly': 'common',
  'Veined': 'limited',
};